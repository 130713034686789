import { formatDTsToReportDateFormat, notNull } from '@cyren/common-lib';
import classNames from 'classnames';
import { HTMLAttributes, memo, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { FaShareSquare, FaArrowCircleDown, FaPaperPlane } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';
import { useCopyToClipboard } from 'usehooks-ts';
import { H5 } from '../../common/typo/H';
import { FlowLineConfigT, PHITranslated } from '../../survey/template/template-types';
import { consolidateFlowItem } from '../../survey/template/utils/flow-template';
import { getStringFromLine, getStringFromPhis } from '../../utils/models/template';
import { useElectron } from '../hooks/use-electron';
import { useToastMsgs } from '../hooks/use-toast-msgs';
import useAuth from '../../auth/use-auth';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isNil } from 'lodash';

function ReportCard({
  line,
  phis,
  demoSignature,
  keyId,
  isAddOnForm,
}: HTMLAttributes<HTMLDivElement> & {
  line: FlowLineConfigT;
  phis?: PHITranslated[];
  demoSignature?: string | null;
  keyId: string;
  isAddOnForm: boolean;
}) {
  const [, copy] = useCopyToClipboard();
  const { isElectron } = useElectron();
  const { showNothingToCopyMsg, showCopiedMsg } = useToastMsgs();
  const [isSendingData, setIsSendingData] = useState(false);
  const [{ jobFunction }] = useAuth();

  const isPhiLine = phis && line.items[0].type === 'rep-phi-list';

  const forceVisible = !isNil(line.visibility?.visible?.find(i => i === jobFunction));
  const forceHidden = !isNil(line.visibility?.hidden?.find(i => i === jobFunction));
  const forceCollapsed = !isNil(line.visibility?.collapsed?.find(i => i === jobFunction));
  if (forceHidden || (line.visibility?.default === 'Hidden' && !forceVisible && !forceCollapsed)) {
    return <></>
  }

  const collapsed = forceCollapsed || (line.visibility?.default === 'Collapsed' && !forceVisible && !forceHidden);

  return (
    <Accordion defaultExpanded={!collapsed || false}
      sx={{
        borderRadius: '1rem',
        margin: '0 0 0 0',
        '&.MuiAccordion-root:first-of-type':
        {
          borderRadius: '1rem'
        },
        '&.MuiAccordion-root.Mui-expanded':
        {
          margin: '0 0 0 0',
        },
        '&.MuiAccordion-root:last-of-type':
        {
          borderRadius: '1rem'
        },
        '&:before': { display: 'none' }  // to hide shadow top border element
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${keyId}-content`}
        id={`${keyId}-header`}
        sx={{
          padding: '0 0.5rem',
          margin: '0.25rem 0',
          minHeight: '0.25rem',
          '.MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '.MuiAccordionSummary-content': {
            margin: '0px 0px',
            minHeight: '0.25rem',
          },
          '&.Mui-expanded': {
            margin: '0.25rem 0rem 0rem 0rem',
            minHeight: '0.25rem',
          }
        }}
      >
        <div className='card-body p-1 gap-0 flex-row justify-between'>
          <div className='flex-center'>
            <H5>
              <div className='flex items-center'>
                {line.label}
                {isAddOnForm &&
                  <FaPaperPlane className="ml-1" size={17} />
                }
              </div>
            </H5>
          </div>

          <div className='flex-row'>
            <div className='flex flex-end relative'>

              {demoSignature && (
                <span className='p-1 cursor-pointer h-fit w-fit'
                  onClick={(event) => {
                    event.stopPropagation();
                  }}>
                  <FaArrowCircleDown size={17} />
                </span>
              )}

              {isElectron && (
                <span
                  className='p-1 cursor-pointer h-fit w-fit'
                  onClick={(event) => {
                    event.stopPropagation();

                    let outputStr = '';
                    if (isPhiLine) {
                      outputStr = getStringFromPhis({ line, phis });
                    } else {
                      outputStr = getStringFromLine(line);
                    }

                    if (outputStr) {
                      setIsSendingData(true);
                      window.electron?.ipcRenderer?.invokeMessage('send', [`${outputStr}\n`]).finally(() => setIsSendingData(false));
                    }
                  }}
                >
                  {isSendingData ? <span className='loading loading-xs loading-spinner'></span> : <FaShareSquare size={17} />}
                </span>
              )}

              <span
                className='p-1 cursor-pointer h-fit w-fit'
                onClick={(event) => {
                  event.stopPropagation();
                  let outputStr = '';
                  if (isPhiLine) {
                    outputStr = getStringFromPhis({ line, phis });
                  } else {
                    outputStr = getStringFromLine(line);
                  }

                  if (outputStr) {
                    copy(`${outputStr}\n`);
                    showCopiedMsg();
                  } else {
                    showNothingToCopyMsg();
                  }
                }}
              >
                <IoMdCopy size={17} />
              </span>
              <span className='p-2 cursor-pointer h-fit w-fit hidden'>
                <BiPencil size={17} />
              </span>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        id={`${keyId}-content`}
        sx={{
          margin: '0px 0px',
          padding: '0rem 0.5rem 0.25rem 0.5rem'
        }}
      >
        <div className='card-body p-4 md:p-1 gap-0'>
          <div>
            {line.items
              .map((item) => {
                const { id: itemId, type, reportConfig } = item;

                if (type === 'rep-report-template') {
                  return (
                    <div key={itemId}>
                      <div>{item.label}</div>
                      {reportConfig?.lines.map((reportLine, lineIdx) => {
                        return (
                          <div key={`${lineIdx}-${reportLine.id}`}>
                            <div className="flex-row gap-2">
                              <span className="flex-center">
                                {reportLine.output}
                              </span>
                              {demoSignature && lineIdx === 0 &&
                                <>
                                  <span className="flex-center" style={{ maxWidth: '5em' }}>
                                    <img src={demoSignature} alt="A patient signature" />
                                  </span>
                                </>}
                            </div>
                            {reportLine.sublines.map((subline, sublineIdx) => {
                              return (
                                <div key={`subline-${sublineIdx}`}>
                                  {subline.output}
                                  {subline.items.map((sublineItem) => {
                                    return (
                                      <div key={sublineItem.id} className='pl-6'>
                                        {sublineItem.output}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                // rendering PHI section
                if (phis && type === 'rep-phi-list') {
                  return (
                    <div key={itemId}>
                      {phis.map((phi, phiIdx, array) => {
                        // don't show redundant date information
                        const onsetDate = formatDTsToReportDateFormat(phi.onsetDate);
                        const lastOnsetDate = phiIdx > 0 ? formatDTsToReportDateFormat(array[phiIdx - 1].onsetDate) : '';
                        const showOnsetDate = onsetDate !== lastOnsetDate;

                        return (
                          <div className={classNames(phiIdx && "pt-2")} key={`${phiIdx} - ${phi.treeKey}-${phi.onsetDate}`}>
                            {showOnsetDate && <div >{onsetDate}</div>}
                            <div className='pl-20'>{phi.lineOutput}</div>
                            {phi.sublinesOutput.map((sublineOutput, sublineIdx) => {
                              return (
                                <div className='pl-20' key={`subline-${sublineIdx}`}>
                                  {sublineOutput}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                const itemOutput = consolidateFlowItem({
                  item,
                });

                return itemOutput;
              })
              // .filter((lineStr) => !!trim(lineStr))
              .filter(notNull)}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(ReportCard);
