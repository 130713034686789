import { beDateToDateObj, dateStrToAgeWithBabyFormat, DateTimeFormat, distDateFormat, Enum_Patientvisit_Status, formatDate, notNull, SystemDate, usePatientProviderViewQuery } from "@cyren/common-lib";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { memo, useState } from "react";
import { useParams } from "react-router";
import { convVisit } from "../../gql-tools/utils/convert";
import { onError } from '../../utils/apollo-utils';
import classNames from "classnames";
import { H3, H4 } from "../../common/typo/H";
import { parseSex } from "../../utils/models/visit";
import StageProgressMeter from "../../admin/report/components/StageProgressMeter";
import Version from "../../app/Version";
import bgImg from '../../app/assets/Group 10189.svg'
import illChartWriting from '../../assets/ill/chart-writing.svg'
import LoadingAnim from "../../components/LoadingAnim";
import { ceil, size } from "lodash";
import { FlowLineConfigT } from "../../survey/template/template-types";
import ReportCard from "../../admin/report/ReportCard";
import ReportAdHocCard from "../../admin/report/components/ReportAdHocCard";
import ReactJoyride, { CallBackProps, STATUS } from "react-joyride";
import ModalSendCommunication from "../../admin/report/components/ModalSendCommunication";
import Logo from "../../components/Logo";

const steps = [
  {
    target: 'body', // Using 'body' as the target ensures it's not focused on a specific element
    /* eslint-disable */
    content: (
      <div style={{ textAlign: 'left' }}>
        <div className="flex-col gap-2">
          <H3>Welcome to CyrenCare</H3>
          <div>Providers will see a clinical report created from the information provided by the patient.</div>
        </div>
      </div>
    ),
    placement: 'center' as 'center', // Center placement for the introductory message
    /* eslint-enable */
    disableBeacon: true,
    disableArrow: true,
  },
  {
    target: '.joyride-step-clinical-reports',
    /* eslint-disable */
    content: (
      <div style={{ textAlign: 'left' }}>
        <div className="flex-col gap-2">
          <H3>Concise clinical language</H3>
          <div>Patient answers are organized into clinical reports familiar to providers.</div>
        </div>
      </div>
    ),
    /* eslint-enable */
    disableBeacon: true,
  },
  {
    target: '.joyride-step-custom-questions',
    /* eslint-disable */
    content: (
      <div style={{ textAlign: 'left' }}>
        <div className="flex-col gap-2">
          <H3>Send SMS Messages to Your Patients</H3>
          <div>Ask questions, collect forms, obtain consent, notify and inform your patients by texting them, and get answers from your workstation.</div>
        </div>
      </div>
    ),
    /* eslint-enable */
    disableBeacon: true
  },
];

function PaProviderReport() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const params = useParams<{
    uuid?: string;
  }>();

  const [openSendCommunication, setOpenSendCommunication] = useState<boolean>(false);
  const [runTour, setRunTour] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);

  const { data, loading } = usePatientProviderViewQuery({
    fetchPolicy: 'cache-and-network',
    skip: !params.uuid,
    variables: {
      uuid: params.uuid!,
    },
    onError,
  });

  if (loading) {
    return (
      <div className='flex-1 h-full overflow-hidden flex-center'>
        <LoadingAnim />
      </div>
    );
  }

  const handleJoyrideCallback = (joyrideData: CallBackProps) => {
    const { action, index, status, type } = joyrideData;
    if (type === 'step:after') {

      if (steps[index + 1]?.target === '.joyride-step-custom-questions') {
        setRunTour(false);
        setOpenSendCommunication(true);

        setTimeout(() => {
          setStepIndex(index + 1);
          setRunTour(true); // Resume the tour after a delay
        }, 250);
      } else if (action === 'prev') {
        setStepIndex(index - 1);
      } else {
        setStepIndex(index + 1);
      }
    }

    // @ts-ignore
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Tour finished or skipped
      setRunTour(false);
      setOpenSendCommunication(false);
    }
    console.log(joyrideData); //eslint-disable-line no-console
  };

  const visit = convVisit(data?.patientProviderView?.visit?.data);
  const reportState = visit?.surveyState?.reportState;

  if (!reportState) {
    return <div>No data</div>;
  }

  const reporting = visit?.status == null || visit?.status === Enum_Patientvisit_Status.Reporting;
  const localeName = reportState.locale || 'english';

  // not all forms will collect a phone, and not all patients will provide one.
  // however, if phone is provided and verfied, enable phone-related functionality
  const patientPhone = visit?.verifiedPhone;
  // demo functionality - if a question with the key 'q-signature' is found, render it at the bottom of the page
  const signature = reportState.treeStates
    ?.map((ts) => {
      return ts?.answerMap?.['q-signature']?.answerValues?.[0];
    })
    .filter(notNull)?.[0];

  const firstVisitValue = reportState.treeStates
    ?.map((ts) => {
      return ts?.answerMap?.['q-pmhx-first-existing']?.answerKeys?.[0];
    })
    .filter(notNull)?.[0];
  const isFirstVisit = firstVisitValue === 'o-yes';

  const losDate = beDateToDateObj(visit?.createdAt);


  const calcedTemplate = data?.patientProviderView?.reports?.calcedTemplate as FlowLineConfigT[];
  const templatesToShow = calcedTemplate?.filter(i => i?.foundTreeState || i?.items.find(j => j.type === 'rep-phi-list'));

  const lineLen = size(templatesToShow);
  const lines1 = templatesToShow?.slice(0, ceil(lineLen / 2)) || [];
  const lines2 = templatesToShow?.slice(ceil(lineLen / 2)) || [];
  const phis = data?.patientProviderView?.reports?.phis || [];

  return (

    <div
      id='cyren-admin'
      className={classNames(' admin-v2 flex flex-1 flex-col h-screen')}
    >
      {visit &&
        <ReactJoyride
          steps={steps}
          stepIndex={stepIndex}
          continuous
          showProgress
          showSkipButton
          run={runTour}

          
          hideCloseButton
          disableOverlayClose
          scrollOffset={250}
          disableOverlay
          callback={handleJoyrideCallback}

          styles={{
            options: {
              arrowColor: '#8255FF',
              backgroundColor: '#8255FF',
              primaryColor: '#000',
              textColor: '#ffffff',
              width: 450,
              zIndex: 1600,
            },
            tooltip: {
              margin: '0 20px',
              maxWidth: 'calc(100% - 40px)',
            },
            overlay: {
              zIndex: 1500,
            },
            buttonBack: {
              color: '#ffffff'
            }
          }}
        />
      }
      {openSendCommunication && (
        <ModalSendCommunication
          onClose={() => {
            setOpenSendCommunication(false);
          }}
          visit={visit!}
        />
      )}

      {/* CONTENTS */}
      <div className='flex-col flex-1 bg-base-300'>
        <div
          className={classNames('h-[7.5rem]')}
          style={{
            backgroundImage: `url("${bgImg}")`,
          }}
        >
          <div className="p-4 flex-row items-center h-full">
            <Logo forDoctor className="w-[4rem]" />
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-1 w-full',
          )}
        >
          <div
            className={classNames(
              'flex pt-8 md:px-10 flex-1'
            )}
          >

            <div className={classNames('flex-1 flex-col gap-3')}>

              <div className='flex-col'>

                <Grid container spacing={3} className={classNames(isMd && "bg-white p-3", "joyride-step-patient-basics")}>
                  <Grid item xs={12} sm={12} md={4} lg={4}
                  >
                    {visit?.firstName && (
                      <div className='flex items-end gap-2 font-bold'>
                        <H4 className='text-[1.5rem] font-extrabold'>
                          {visit?.firstName} {visit?.lastName}
                        </H4>
                        <span className='text-sm pb-[0.12rem]'>
                          {parseSex({ sexStr: visit?.sex })} /
                          {dateStrToAgeWithBabyFormat(visit.dob, {
                            inputFormat: SystemDate,
                          })}
                        </span>
                      </div>
                    )}

                    {localeName && (
                      <div className='text-sm font-semibold capitalize opacity-60'>{localeName}</div>
                    )}

                    {visit && <div className="pt-2 pb-2"><StageProgressMeter visit={visit} /></div>}

                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} className="">
                    {reporting && (
                      <>
                        <div className='flex-center'>
                          <img className="max-h-20" src={illChartWriting} />
                        </div>
                        <div className='flex-center'>Patient is writing the report...</div>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}
                    className={classNames("flex-none flex-col",
                      isMd && "pt-3",
                      isMdUp && "justify-end")}>
                    <div className={classNames(
                      'flex-col flex-none gap-2 text-sm opacity-80',
                      isMd && "w-full border-t",
                      isMdUp && 'border-transparent pt-0 text-end')}>
                      {firstVisitValue ? (
                        <div>{isFirstVisit ? 'New patient' : 'Returning patient'}</div>
                      ) : (
                        <div>-</div>
                      )}
                      <div>
                        {formatDate(losDate, {
                          formatStr: DateTimeFormat,
                        })}
                      </div>
                      {/* <div>08/20/23 10:47</div> */}
                      <div>
                        {'LOS: '}
                        {distDateFormat(losDate)}
                      </div>
                      {/*
              {(reporting || pendingAdHocQuestions || size(missingForms) > 0) && <AutoReload
                loading={loading}
                timeoutSec={15}
                onReload={() => {
                  refetch();
                  addOnRefetch();
                  patientMessageRefetch();
                }}
              />
              }
              */}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classNames("flex-col flex-1 gap-5", "joyride-step-clinical-reports")}>
                <div className='grid flex-1 grid-cols-1 gap-2 md:gap-5 md:grid-cols-2'>
                  <div className='flex-col flex-1 gap-2 md:gap-5'>
                    {lines1.map((line, idx) => {
                      const hasDownloadableForm = line.items?.[0]?.reportConfig?.treeKey?.endsWith('consent')
                        ? line?.output : null;
                      const demoSignature = hasDownloadableForm && signature;
                      const key = `${idx}-${line.id}`;
                      const joyrrideClassname = `joyride-step-${line?.label?.replace(' ', '-').toLowerCase()}`
                      return (
                        <div className={joyrrideClassname}>
                          <ReportCard key={key} keyId={key} line={line} phis={phis} isAddOnForm={false} demoSignature={demoSignature} />
                        </div>
                      );
                    })}
                  </div>
                  <div className='flex-col flex-1 gap-2 md:gap-5'>
                    {lines2.map((line, idx) => {
                      const hasDownloadableForm = line.items?.[0]?.reportConfig?.treeKey?.endsWith('consent')
                        ? line?.output : null;
                      const demoSignature = hasDownloadableForm && signature;
                      const key = `${idx}-${line.id}`;
                      const joyrrideClassname = `joyride-step-${line?.label?.replace(' ', '-').toLowerCase()}`
                      return (
                        <div className={joyrrideClassname}>
                          <ReportCard key={key} keyId={key} line={line} phis={phis} isAddOnForm={false} demoSignature={demoSignature} />
                        </div>
                      );
                    })}
                    {patientPhone &&
                      <ReportAdHocCard
                        adHocData={visit?.adHocState}
                        patientMessages={[] /*patientMessages*/}
                        locale={reportState.locale}
                      />
                    }
                  </div>
                </div>


                <div className='h-24 pb-10'></div>
                <div className='h-24 pb-10'></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={classNames('block md:hidden')}><Version /></div>
    </div>

  );
}

export default memo(PaProviderReport);
