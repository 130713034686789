import { Enum_Translation_Locale } from '@cyren/common-lib';
import { isEmpty, size } from 'lodash';
import { memo, useEffect, useRef } from 'react';
import useAppState from '../../app/use-app-state';
import LoadingAnim from '../../components/LoadingAnim';
import { useData } from '../../report/use-data';
import { usePatientTreeData } from '../../report/use-patient-tree-data';
import { useReportState } from '../hooks/use-report';
import { usePaSurveyParams, useSurveyState } from '../hooks/use-survey-state';
import { TOptions } from '../pa-prop-types';
import { Key } from '../patient-types';
import PaQuestionTree from './PaQuestionTree';
import GlobalErrorHandler from '../../components/GlobalErrorHandler';

export function PaTreePure({
  treeKey,
  onNext,
  onSave,
  isLastTree,
  preloadNext,
  tOptions,
  locale,
  noHeader,
}: {
  isLastTree?: boolean;
  treeKey?: Key;
  onNext?: () => void;
  onSave?: () => void;
  preloadNext?: boolean;
  tOptions?: TOptions;
  locale: Enum_Translation_Locale;
  noHeader?: boolean;
}) {
  const [
    {
      survey,
      loadingTrans,
      loadingQss,
      // dataState: { surveyReady },
    },
  ] = usePatientTreeData({
    treeKey,
    autoRefetch: true,
  });

  const currIdx = survey?.questionTreeKeys?.findIndex((k) => k === treeKey);
  const nextTreeKey = currIdx && currIdx !== -1 ? survey?.questionTreeKeys?.[currIdx + 1] : null;

  // preload
  usePatientTreeData({
    autoRefetch: preloadNext,
    treeKey: nextTreeKey,
  });

  const [{ treeState, reportState }, { updateAnswer, updateAnswerValue, onUiMetadataChange }] = useReportState({
    treeKey,
  });
  const [, { recordUnhandledError }] = useSurveyState();

  const lastInterimUpdate = useRef(Date.now());
  const pendingInterimUpdate = useRef<NodeJS.Timeout | null>(null);

  const [, { onInterimSave }] = useSurveyState();

  const sendInterimUpdate = async () => {
    // eslint-disable-next-line
    console.log('Executing interim save ###');
    try {
      onInterimSave();
    } catch (error) {
      // eslint-disable-next-line
      console.log('Interim save error:', error);
    }

    return null;
  }

  /* Monitor the report state for changes so that interim modifications can be written to the server */
  useEffect(() => {
    // useful debugging logic, preserving it for future use
    /*
    console.log('interim report state change detected', reportState);
    let result = '';
    reportState.treeStates.forEach((i) => {
      if (i && i.answerMap && i.answerMap) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(i.answerMap)) {
          let displayValue = null;
          if (value && value?.answerKeys) {
            displayValue = `${value.answerKeys[0]}`
          }
          if (value && value.answerValues) {
            displayValue += `:${value.answerValues[0]}`
          }
          result += `${key}: ${displayValue}\n`;
        }
      }
    });
    console.log(result);
    reportState.treeStates.forEach((i) => {
      console.log(i.treeKey, i);
    });
    */

    const now = Date.now();
    const intervalGap = 5000;  // don't write updates less than 5 seconds apart
    if (pendingInterimUpdate.current) {
      clearTimeout(pendingInterimUpdate.current);
      pendingInterimUpdate.current = null;
    }

    const nextInterval = Math.max(10, intervalGap - (now - lastInterimUpdate.current));
    pendingInterimUpdate.current = setTimeout(() => {
      lastInterimUpdate.current = Date.now();
      sendInterimUpdate();
    }, nextInterval);

    return () => {
      // Clean up the timer on component unmount or variable change
      if (pendingInterimUpdate.current) {
        clearTimeout(pendingInterimUpdate.current);
        pendingInterimUpdate.current = null;
      }
    };

  }, [reportState]);

  const logUnhandledError = (message: string | Event, source?: string, lineno?: number, colno?: number, error?: Error): void => {
    recordUnhandledError({ message, source, lineno, colno, error });
  }

  const [, { getTreeByKey, getQuestionsByKeys }] = useData();
  const tree = treeKey ? getTreeByKey(treeKey) : null;
  const qsKeys = tree?.entryQuestionKeys || [];
  const questions = !isEmpty(qsKeys) ? getQuestionsByKeys(qsKeys) : null;
  const questionsReady = !isEmpty(questions) || size(qsKeys) === size(questions);

  if (!treeKey) {
    // TODO redirect
    return <div>Tree key not found</div>;
  }

  if (!questionsReady || loadingTrans || loadingQss) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-5 w-full' />
      </div>
    );
  }

  return (
    <>
      <GlobalErrorHandler logError={logUnhandledError} />


      <PaQuestionTree
        level={0}
        treeKey={treeKey}
        hasNext={!isLastTree}
        treeState={treeState}
        updateAnswer={updateAnswer}
        updateValue={updateAnswerValue}
        onUiMetadataChange={onUiMetadataChange}
        onNext={onNext}
        onSave={onSave}
        tOptions={tOptions}
        locale={locale}
        noHeader={noHeader}
      />
    </>
  );
}

function PaTreePage() {
  const [{ isLastTree }, { onSave, goNextTree }] = useSurveyState();
  const { treeKey } = usePaSurveyParams();

  const [{ localeKey }] = useAppState();

  return (
    <div className='t_PaTreePage flex-1 flex-col gap-5'>

      <PaTreePure
        preloadNext
        treeKey={treeKey}
        isLastTree={isLastTree}
        onNext={() => {
          goNextTree();
        }}
        onSave={() => {
          onSave();
        }}
        locale={localeKey as Enum_Translation_Locale}
      />
    </div>
  );
}

export default memo(PaTreePage);
