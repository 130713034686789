import { memo, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
// https://github.com/lanjingling0510/react-mobile-datepicker#proptypes

type Props = {
  onUpdate?: (data: string | null) => void,
  className?: string,
  initialValue?: string
};

function SignaturePad({ ...props }: Props) {

  const { sltStr } = useStaticLocales();

  // don't let the signature box be wider than the viewport
  const width = Math.floor(Math.min(475, 0.80 * window.innerWidth));
  const height = 150;

  const { onUpdate, className, initialValue } = props;
  useEffect(() => {
    sigCanvas.current?.fromDataURL(initialValue, { width, height });
  }, [initialValue]);

  // eslint-disable-next-line
  const sigCanvas = useRef<any | null>(null);


  return (
    <div className="flex-col gap-2">
      <div
        className={className}
      >

        <SignatureCanvas
          ref={sigCanvas}
          penColor='black'
          canvasProps={{ width, height, className: 'sigCanvas' }}
          onEnd={() => {
            if (onUpdate)
              onUpdate(sigCanvas.current?.toDataURL('image/png'));
          }}
        />
      </div>
      <div className='flex-none flex justify-between'>
        <div className="p-2">
          {sltStr({ key: 'msg-electronic-signature' })}
        </div>
        <button className='btn' onClick={() => {
          sigCanvas.current?.clear();
          if (onUpdate)
            onUpdate('');
        }}>
          {sltStr({ key: 'btn-reset', capit: true })}
        </button>
      </div>
    </div>
  );
}

export default memo(SignaturePad);
