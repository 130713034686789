import { isNotEmpty, notNull, containsNoUppercase } from '@cyren/common-lib';
import classNames from 'classnames';
import { capitalize, first, uniq } from 'lodash';
import { memo, useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import Modal from '../../components/Modal';
import { useQuestionHelpers } from '../../report/use-question-helpers';
import { getMediumImgLink } from '../../utils/image';
import { useTranslations } from '../hooks/use-translations';
import { QuestionProps } from '../pa-prop-types';
import { QsErrorType } from '../patient-types';
import QuestionField from './QuestionField';
import { useQuestion } from './use-question';

function Question(props: QuestionProps) {
  const {
    level,
    dirty,
    error,
    errorMsgs,
    locale,
    question: questionRaw,
    updateAnswer: updateAnswerPure,
    tOptions,
    mode,
    textOverrides
  } = props;

  const hasRequiredError = dirty && error;
  const showError = dirty && (error || isNotEmpty(errorMsgs));

  // SHOW ERROR ONLY WHEN 'ERROR' IS SET TO TRUE.
  const errors = dirty
    ? (uniq([
      //
      hasRequiredError ? ('required' as const) : null,
      ...(errorMsgs || []),
    ]).filter(notNull) as QsErrorType[])
    : [];



  const { sltStr, slt } = useStaticLocales();

  const errorMessage = isNotEmpty(errors) ? errors.map((errMsg) => {
    const translatedString = sltStr({ key: errMsg });
    // don't force the string to be capitalized if there is already mixed case text inside it - in that
    // case, assume the translator/author knew what they were doing
    return containsNoUppercase(translatedString) ? capitalize(translatedString) : translatedString;
  }).join(', ') : null;

  const isDisplayMode = mode === 'display';

  const [ refineQuestion ] = useQuestionHelpers();
  const [{ question }] = refineQuestion({ question: questionRaw, isDisplayMode });

  const [, { t }] = useTranslations({ locale });

  // eslint-disable-next-line
  // console.log('treeState', treeState);

  const updateAnswer = updateAnswerPure
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (p: any) => {
      // console.log('handler level', level, p);
      updateAnswerPure({
        ...p,
        level,
      });
    }
    : updateAnswerPure;

  const enhancedProps = {
    ...props,
    question,
    updateAnswer,
  };

  const { isMarkdown, isStageMileMarker } = useQuestion(question);

  const [openDesc, setOpenDesc] = useState(false);

  if (!question) {
    return null;
  }

  const { exampleKey, required, desc, descInline, questionKey } = question;
  const descT = t(desc, tOptions, { fallbackValue: null });
  const descInlineT = textOverrides?.inlineDescription || t(descInline, tOptions, { fallbackValue: null });

  const imageUrl = getMediumImgLink(first(question?.images?.data));
  const questionTitle = t(questionKey, tOptions, { fallbackValue: '' });

  const hideOptionalLabel = isMarkdown || isStageMileMarker;

  const exampleLabel = t(exampleKey, {}, { fallbackValue: '' });


  return (
    <div
      id={questionKey}
      className={classNames('t_Question', 'gap-4 flex-col rounded-xl', isDisplayMode && 'p-3 bg-white')}
    >
      <div className='flex-col'>
        <div
          className='flex gap-2 text-base'
          onClick={() => {
            // eslint-disable-next-line
            console.log(question);
          }}
        >
          {isMarkdown && !isStageMileMarker && questionTitle && (
            <div className='prose'>
              <ReactMarkdown>{questionTitle}</ReactMarkdown>
            </div>
          )}

          {!isMarkdown && !isStageMileMarker && (
            <span className={classNames(showError && 'text-error')}>
              {questionTitle || textOverrides?.questionText || questionKey}
              {required && <span title={sltStr({key: "required"})}> *</span>}
            </span>
          )}

          {descT && (
            <>
              <span
                className='opacity-50 flex-center cursor-pointer'
                onClick={() => {
                  setOpenDesc(true);
                }}
              >
                <FaRegQuestionCircle size={18} />
              </span>

              {openDesc && (
                <Modal open className={classNames('flex p-0 overflow-hidden bg-transparent')}>
                  <div className='flex-1 flex-col overflow-hidden bg-transparent justify-end'>
                    <div className='flex-col gap-2 overflow-hidden pb-4 w-full bg-white rounded-t-3xl lg:rounded'>
                      <div className='flex-none flex-center'>
                        {/* top modal handle */}
                        <div className='bg-black bg-opacity-10 rounded-full w-10 h-2 mt-3'></div>
                      </div>
                      <div className='flex-none flex-center-y gap-2 px-5 justify-end h-0'>
                        <div className='w-0 overflow-visible'>
                          <button
                            className={classNames('p-2 inline')}
                            onClick={async () => {
                              setOpenDesc(false);
                            }}
                          >
                            <MdClose size={20} className='opacity-60 -top-0 relative right-7' />
                          </button>
                        </div>
                      </div>

                      <div className='flex-1 flex-col gap-3 overflow-auto px-5 whitespace-pre-wrap'>
                        {descT}
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </>
          )}
        </div>
        {descInlineT && <div className='text-desc whitespace-pre-wrap'>{descInlineT}</div>}

        {!hideOptionalLabel && !required && (
          <span className='text-desc'>({slt({ key: 'optional' })})</span>
        )}

        {exampleLabel && (
          <div className='pt-1 text-desc whitespace-pre-wrap'>
            {capitalize(sltStr({ key: 'example' }))}: {exampleLabel}
          </div>
        )}
      </div>

      {isNotEmpty(errorMessage) && (
        <div>
          <span className='text-error'>
            {errorMessage}
          </span>
        </div>
      )}

      <QuestionField {...enhancedProps} question={question} />

      {imageUrl && (
        <div className='flex-1'>
          <img className='object-contain w-full' src={imageUrl} />
        </div>
      )}
    </div>
  );
}

export default memo(Question);
